import React from 'react';
import { graphql } from 'gatsby';

import { withPreview } from 'gatsby-source-prismic';

import SEO from '../components/seo';
import Layout from '../components/layout';
import ProjectHeader from '../components/projects/ProjectHeader';
import ProjectSliceZone from '../slices/ProjectSliceZone';
import ProjectSlider from '../components/projects/ProjectSlider';

const UnderConstruction = ({
  data: { prismicUnderConstruction, projects },
}) => {
  const { data } = prismicUnderConstruction;

  return (
    <Layout headerColor='white'>
      <SEO title={`${data.project_name.text}`} />
      <ProjectHeader {...data} />
      <div id='project-content'>
        {data.body && (
          <ProjectSliceZone
            allSlices={data.body}
            projectColor={data.project_color}
          />
        )}
      </div>
    </Layout>
  );
};

export default withPreview(UnderConstruction);

export const pageQuery = graphql`
  query projectBySlugAndProjectBySlug($uid: String!) {
    prismicUnderConstruction(uid: { eq: $uid }) {
      uid
      data {
        awards {
          award {
            fluid {
              ...GatsbyPrismicImageFluid
            }
          }
        }
        project_name {
          text
        }
        subheading {
          text
        }
        project_intro {
          html
        }
        feature_image {
          fluid {
            ...GatsbyPrismicImageFluid
          }
        }
        project_color
        body {
          ... on PrismicUnderConstructionBodyIntroText {
            id
            slice_type
            primary {
              quote {
                text
              }
              text {
                html
              }
            }
          }
          ... on PrismicUnderConstructionBodySingleColumnIntroText {
            id
            slice_type
            primary {
              quote {
                text
              }
              text {
                html
              }
            }
          }
          ... on PrismicUnderConstructionBodyImageText {
            id
            slice_type
            primary {
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              title {
                text
              }
              text {
                html
              }
              vertical_text_postion
              horizontal_text_position
            }
          }
          ... on PrismicUnderConstructionBodyCarouselSlider {
            id
            slice_type
            items {
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              align
            }
          }
          ... on PrismicUnderConstructionBodyAwardsTable {
            id
            slice_type
            primary {
              title {
                text
              }
            }
            items {
              award_title {
                text
              }
              link {
                url
                target
              }
            }
          }
        }
      }
    }
    projects: allPrismicUnderConstruction(
      filter: { uid: { ne: $uid } }
      sort: { fields: [data___page_weight], order: ASC }
    ) {
      edges {
        node {
          uid
          data {
            project_name {
              text
            }
            subheading {
              text
            }
            slider_image {
              fluid {
                ...GatsbyPrismicImageFluid
              }
            }
            project_color
            page_weight
          }
        }
      }
    }
  }
`;
